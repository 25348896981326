import File from '@local/web-design-system-2/dist/icons/File';
import Upload from '@local/web-design-system-2/dist/icons/Upload';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';

import { type DownloadFileResponse } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { FileActions } from 'src/pages/workspaceFilesPage/fileActions/FileActions';
import {
    CREATED_ON,
    FILE_HISTORY,
    INSUFFICIENT_WORKSPACE_PERMISSION,
    UPLOAD_NEW_VERSION,
} from 'src/strings';

import { useFileHandler } from '../../../hooks/useFileHandler';

interface Props {
    file?: DownloadFileResponse;
}

export const VersionHistory = ({ file }: Props) => {
    const { classes } = useStyles();
    const { workspaceUserRole, workspaceId } = useWorkspaceContext();
    const uploadFieldRef = useRef<HTMLInputElement>(null);

    const { uploadingCount } = useFileUploadContainer({ workspaceId });

    const isViewer = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    const { uploadFileById } = useFileHandler();

    if (!file?.versions) {
        return null;
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.currentTarget.files;
        if (!fileList || fileList.length < 1) {
            return;
        }
        await uploadFileById(fileList[0], file, uploadFieldRef);
    };

    return (
        <Stack spacing={2} mt={5}>
            <Typography automation-id="file-history-label" variant="h6">
                {FILE_HISTORY}
            </Typography>
            <Divider />
            <Tooltip title={isViewer ? INSUFFICIENT_WORKSPACE_PERMISSION : ''}>
                <Button
                    component="label"
                    automation-id="upload-new-version-button"
                    variant="outlined"
                    fullWidth
                    size="large"
                    color="secondary"
                    disabled={uploadingCount > 0 || isViewer}
                    startIcon={<Upload />}
                >
                    <Typography variant="body1">{UPLOAD_NEW_VERSION}</Typography>
                    <input
                        ref={uploadFieldRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </Button>
            </Tooltip>

            <Box className={classes.versionHistoryList}>
                {file.versions.map((version) => (
                    <Box
                        key={version.version_id}
                        className={classes.version}
                        style={{ marginLeft: '16px' }}
                    >
                        <Grid direction="column" container>
                            <Box className={classes.versionHistory}>
                                <File />
                                <Box className={classes.versionHistoryItem}>
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'pre',
                                            overflow: 'hidden',
                                        }}
                                        automation-id="version-history-file-name"
                                        fontWeight={500}
                                        title={file.name}
                                        variant="subtitle1"
                                    >
                                        {file.name}
                                    </Typography>
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: '8px',
                                            }}
                                        >
                                            <Typography
                                                sx={{ width: '70px' }}
                                                variant="body2"
                                                automation-id="version-history-id"
                                            >
                                                ID:
                                            </Typography>
                                            <Typography variant="body2" sx={{ width: '150px' }}>
                                                {version.version_id}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography sx={{ width: '75px' }} variant="body2">
                                                {`${CREATED_ON}:`}
                                            </Typography>
                                            <PropertyDate
                                                timestamp={version.created_at}
                                                automationId="version-history-created-at"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <FileActions file={file} version={version} />
                            </Box>
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Stack>
    );
};
