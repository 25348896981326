import {
    ScreenSpaceEventHandler,
    ScreenSpaceEventType,
    Label,
    PointPrimitive,
    LabelCollection,
    PointPrimitiveCollection,
    Cartesian3,
    Cartographic,
} from '@cesium/engine';
import { useEffect } from 'react';

export function useHandlePrimitiveClick(
    cesiumWidget: any,
    resetClusters: () => void,
    setSelectedCluster: (cluster: any) => void,
    myClusters: any[],
) {
    const CLUSTER_SIZE_THRESHOLD = 7;
    const ZOOM_IN_THRESHOLD = 200000;
    const ZOOM_DURATION = 0.5;
    const ZOOM_FACTOR = 5;

    const clusterClick = (primitivePosition: Cartesian3) => {
        const offsetDistance = 0.5;

        const offset = Cartesian3.multiplyByScalar(
            Cartesian3.normalize(primitivePosition, new Cartesian3()),
            offsetDistance,
            new Cartesian3(),
        );
        const offsetPosition = Cartesian3.add(primitivePosition, offset, new Cartesian3());

        const offsetCartographicPosition = Cartographic.fromCartesian(offsetPosition);

        cesiumWidget.camera.flyTo({
            destination: Cartesian3.fromRadians(
                offsetCartographicPosition.longitude,
                offsetCartographicPosition.latitude,
                cesiumWidget.camera.positionCartographic.height * (1 / ZOOM_FACTOR),
            ),
            duration: ZOOM_DURATION,
            complete: () => {
                // eslint-disable-next-line no-param-reassign
                primitivePosition = cesiumWidget.camera.position;
            },
        });
    };

    useEffect(() => {
        if (!cesiumWidget) return;

        const handler = new ScreenSpaceEventHandler(cesiumWidget.scene.canvas);

        handler.setInputAction((click: any) => {
            const pickedObject = cesiumWidget.scene.pick(click.position);
            if (
                pickedObject.primitive instanceof Label ||
                pickedObject.primitive instanceof PointPrimitive
            ) {
                const foundID = pickedObject.primitive.id;

                for (let i = 0; i < cesiumWidget.scene.primitives.length; i += 1) {
                    const primitive = cesiumWidget.scene.primitives.get(i);

                    if (primitive instanceof LabelCollection) {
                        for (let j = 0; j < primitive.length; j += 1) {
                            const label = primitive.get(j) as Label;
                            if (label.id === foundID) {
                                if (
                                    Number(label.text) <= CLUSTER_SIZE_THRESHOLD &&
                                    cesiumWidget.camera.positionCartographic.height <
                                        ZOOM_IN_THRESHOLD
                                ) {
                                    label.show = false;
                                    setSelectedCluster(
                                        myClusters.find(
                                            (cluster) => cluster.id === pickedObject.primitive.id,
                                        ),
                                    );
                                } else {
                                    clusterClick(label.position);
                                }
                            }
                        }
                    } else if (primitive instanceof PointPrimitiveCollection) {
                        for (let j = 0; j < primitive.length; j += 1) {
                            const point = primitive.get(j) as PointPrimitive;
                            if (point.id === foundID) {
                                // Find the corresponding label to check its text
                                let labelText = '';
                                for (let k = 0; k < cesiumWidget.scene.primitives.length; k += 1) {
                                    const labelPrimitive = cesiumWidget.scene.primitives.get(k);
                                    if (labelPrimitive instanceof LabelCollection) {
                                        for (let l = 0; l < labelPrimitive.length; l += 1) {
                                            const label = labelPrimitive.get(l) as Label;
                                            if (label.id === foundID) {
                                                labelText = label.text;
                                                break;
                                            }
                                        }
                                    }
                                    if (labelText) break;
                                }
                                if (
                                    Number(labelText) <= CLUSTER_SIZE_THRESHOLD &&
                                    cesiumWidget.camera.positionCartographic.height <
                                        ZOOM_IN_THRESHOLD
                                ) {
                                    point.show = false;
                                    setSelectedCluster(
                                        myClusters.find(
                                            (cluster) => cluster.id === pickedObject.primitive.id,
                                        ),
                                    );
                                } else {
                                    clusterClick(point.position);
                                }
                            }
                        }
                    }
                }
            }
        }, ScreenSpaceEventType.LEFT_CLICK);

        // eslint-disable-next-line consistent-return
        return () => {
            handler.destroy();
        };
    }, [cesiumWidget, resetClusters, setSelectedCluster, myClusters]);
}
